/* compileOption={"opt":"-b -s 1"} */
$(function() {
	//=== append direct app link <= でも直帰が激増するのでNGかも
	// if (navigator.userAgent.match(/iPad/)) {
	// $('<meta name="apple-itunes-app" content="app-id=892262679">').prependTo($("head"));
	// } else {
	// $('<meta name="apple-itunes-app" content="app-id=892138785">').prependTo($("head"));
	// }

	window.KW = window.KW || {};
	KW.Web.init({
		css : null,
		script : [//
		"js/lib/jquery.lazyload.min.js", //
		"js/lib/fancybox.js", //
		"js/lib/marked.min.js"],
		template : "data-templates.html"
	}).done(function() {
		KW.App = new App();
	});
});

/*
 *
 *
 * App
 */
function App() {
	var verStr = "v27";
	var userInfo;
	// var abTestVal = "";
	var divsOrder = ["AREA_HEADER", "AREA_MAIN", "AREA_FOOTER"];
	var routing = {
		premium : {
			title : "IdeaGrid - プレミアムなたたずまい",
			titleEn : "IdeaGrid - Premium Appearance",
			divs : {
				AREA_HEADER : [//
				// ["header-simple", null]//
				],
				AREA_MAIN : [//
				["header-simple", null], //
				["teaser2_premium", null] //
				],
				AREA_FOOTER : [//
				["footer-spacer", null]//
				]
			}
		},
		features_table : {
			title : "IdeaGrid - 機能比較表",
			titleEn : "IdeaGrid - Features Table",
			divs : {
				AREA_HEADER : [//
				// ["header-simple", null]//
				],
				AREA_MAIN : [//
				["header-simple", null], //
				["get_it", getItController, "ONLY_TABLE"] //
				],
				AREA_FOOTER : [//
				["footer-spacer", null]//
				]
			}
		},
		other_features : {
			title : "IdeaGrid - IdeaGridの特長",
			titleEn : "IdeaGrid - Features",
			divs : {
				AREA_HEADER : [//
				// ["header-simple", null]//
				],
				AREA_MAIN : [//
				["header-simple", null], //
				["concept", conceptController, "MODE_HIDE_ICONS"], //
				],
				AREA_FOOTER : [//
				["footer-spacer", null]//
				]
			}
		},
		function_list : {
			title : "IdeaGrid - IdeaGridの機能",
			titleEn : "IdeaGrid - Functions",
			divs : {
				AREA_HEADER : [//
				// ["header-simple", null]//
				],
				AREA_MAIN : [//
				["header-simple", null], //
				["function_list", functionListController], //
				["about_cloud", null], //
				["dropbox_banner", dropboxBannerController] //
				],
				AREA_FOOTER : [//
				["footer-spacer", null]//
				]
			}
		},
		diff_from_others : {
			title : "IdeaGrid - 一般的なメモアプリとの違いは何？",
			titleEn : "IdeaGrid - What Is The Difference From The Other Memo Apps?",
			divs : {
				AREA_HEADER : [//
				// ["header-simple", null]//
				],
				AREA_MAIN : [//
				["header-simple", null], //
				["teaser2_shikumi", shikumiController, "MODE_HIDE_ICONS"]//
				],
				AREA_FOOTER : [//
				["footer-spacer", null]//
				]
			}
		},
		promo : {
			title : "プロモーション",
			titleEn : "Promotion",
			divs : {
				AREA_HEADER : [//
				["header-set", headerController]//
				],
				AREA_MAIN : [//
				// ["lang_selector", langController], //
				["promo", promoController] //
				],
				AREA_FOOTER : [//
				["global-footer", footerController]//
				]
			}
		},
		release_note : {
			title : "IdeaGrid リリースノート",
			titleEn : "IdeaGrid Release Note",
			divs : {
				AREA_HEADER : [//
				["header-simple", null]
				],
				AREA_MAIN : [//
				["lang_selector", langController], //
				["md_placeholder", mdPlaceHolderController, "md/release_note.md"] //
				],
				AREA_FOOTER : [//
				["global-footer", footerController, "MODE_SIMPLE"]//
				]
			}
		},
		release_note_v140 : {
			title : "IdeaGrid v1.4 リリースノート",
			titleEn : "IdeaGrid v1.4 Release Note",
			divs : {
				AREA_HEADER : [//
				["header-simple", null]
				],
				AREA_MAIN : [//
				["lang_selector", langController], //
				["md_placeholder", mdPlaceHolderController, "md/release_note_v140.md"] //
				],
				AREA_FOOTER : [//
				["global-footer", footerController, "MODE_SIMPLE"]//
				]
			}
		},
		teaser : {
			title : "X IdeaGrid - アイデア専用「プレミアム」手帳アプリ",
			titleEn : "X IdeaGrid - The Premium Planner App for Ideas",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["lp_eyecatch", lpEyeCatchController], //
				["lp_lead_2", lpLeadController], //
				["davinci", null], //
				["voice", null], //
				["more_details", moreDetailsController] //
				],
				AREA_FOOTER : [//
				["global-footer", footerController, "MODE_SIMPLE"]//
				]
			}
		},
		//=== 直帰率 60%以上なので本HPへの動線としては弱い
		//=== →中途半端に情報があるが、購入とかをボカしているからかも
		// teaser_VIII : {
		// title : "VIII IdeaGrid - アイデア専用「プレミアム」手帳アプリ",
		// titleEn : "VIII IdeaGrid - The Premium Planner App for Ideas",
		// divs : {
		// AREA_HEADER : [//
		// ],
		// AREA_MAIN : [//
		// ["lp_eyecatch", lpEyeCatchController], //
		// ["lp_lead_2", lpLeadController], //
		// // ["lp_joint", lpJointController, "TYPE_DAVINCI"], //
		// ["davinci", null], //
		// ["lp_joint", lpJointController, "TYPE_VIDEO"], //
		// ["watch_video", watchVideoController], //
		// ["lp_joint", lpJointController, "TYPE_VOICE"], //
		// ["voice", null], //
		// ["lp_joint", lpJointController, "TYPE_SHIKUMI"], //
		// ["teaser2_shikumi", shikumiController, "MODE_HIDE_ICONS"], //
		// ["lp_joint", lpJointController, "TYPE_PREMIUM"], //
		// ["teaser2_premium", null], //
		// // ["teaser2_more", null], //
		// ["lp_joint", lpJointController, "TYPE_USAGE"], //
		// ["grid_style", null], //
		// ["lp_joint", lpJointController, "TYPE_CAMPAIGN"], //
		// ["lp_campaign", lpCampaignController] //
		// // ["more_details", moreDetailsController] //
		// ],
		// AREA_FOOTER : [//
		// // ["lp_floating_footer", floatingFooterController], //
		// ["global-footer", footerController]//
		// ]
		// }
		// },
		campaign : {
			title : "IdeaGrid スペシャルキャンペーン",
			titleEn : "IdeaGrid Special Campaign",
			template : "data-templates.html",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["campaign", campaignController] //
				],
				AREA_FOOTER : [["global-footer", footerController]//
				]
			}
		},
		trial : {
			title : "IdeaGrid 10日間体験キットのご案内",
			titleEn : "IdeaGrid Trial Registration",
			template : "data-templates.html",
			divs : {
				AREA_HEADER : [//
				["header-set", headerController]//
				],
				AREA_MAIN : [//
				["lang_selector", langController], //
				["trial", trialController] //
				],
				AREA_FOOTER : [["global-footer", footerController]//
				]
			}
		},
		release_note_v110 : {
			title : "IdeaGrid v1.1.0 リリースノート",
			titleEn : "IdeaGrid v1.1.0 Release Note",
			template : "data-templates-guide.html",
			divs : {
				AREA_HEADER : [// 
				["header-simple", null]
				],
				AREA_MAIN : [//
				["lang_selector", langController], //
				["dropbox_catch", null], //
				["dropbox", null], //
				["v110_notes", null] //
				//["get_new_version", downloadControlelr], //
				//["v110_workaround", null] //
				],
				AREA_FOOTER : [["global-footer", footerController]//
				]
			}
		},
		guide : {
			title : "IdeaGrid ファンクションガイド",
			titleEn : "IdeaGrid Function Guide",
			template : "data-templates-guide.html",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["lang_selector", langController], //
				["guide_title", null], //
				["shortcut", null], //
				["dropbox", null] //
				],
				AREA_FOOTER : [["global-footer", footerController]//
				]
			}
		},
		thanks_for_1st_users : {
			title : "いち早くユーザーになっていただいた皆さまへ",
			titleEn : "いち早くユーザーになっていただいた皆さまへ",
			template : "data-templates-invitation.html",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["gift_inquiry", giftInquiryController] //
				],
				AREA_FOOTER : [["global-footer", footerController]//
				]
			}
		},
		back_soon : {
			title : "IdeaGrid - アイデア専用「プレミアム」手帳アプリ",
			titleEn : "IdeaGrid - The Premium Planner App for Ideas",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["back_soon", null] //
				],
				AREA_FOOTER : [//
				]
			}
		},
		index : {
			title : "IdeaGrid - アイデア専用「プレミアム」手帳アプリ",
			titleEn : "IdeaGrid - The Premium Planner App for Ideas",
			divs : {
				AREA_HEADER : [//
				["header-set", headerController]//
				],
				AREA_MAIN : [//
				["lang_selector", langController], //
				["top_logo", null], //
				["teaser2_catch", null], //
				// ["teaser2_get_button_only", getButtonController], //
				// ["sale_banner", saleBannerController], //
				["news", newsController, 3], //
				// ["v140_banner", v140BannerController], //
				["watch_video", watchVideoController], //
				["ws_banner", wsBannerController, "after_video"], //
				// ["hiwa_banner", hiwaBannerController], //
				["voice2", voiceController], //
				["concept", conceptController, "MODE_HIDE_ICONS"], //
				["teaser2_shikumi", shikumiController, "MODE_HIDE_ICONS"], //
				["teaser2_premium", null], //
				["about_cloud", null], //
				["dropbox_banner", dropboxBannerController], //
				// ["ws_banner", wsBannerController, "after_usage"], //
				["function_list", functionListController], //
				// ["ws_banner", wsBannerController, "after_functions"], //
				["teaser2_more", null], //
				["voice1", voiceController], //
				["grid_style", null], //
				["voice", null], //
				["get_it", getItController] //
				// ["limited_sale", limitedSaleController, "2016/8/31"] //
				],
				AREA_FOOTER : [//
				["floating_footer", floatingFooterController], //
				["global-footer", footerController]//
				]
			}
		},
		unsubscribe : {
			title : "お知らせメール解除",
			titleEn : "お知らせメール解除",
			template : "data-templates-invitation.html",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["unsubscribe", unsubscribeController, "MODE_IDEAGRID"] //
				],
				AREA_FOOTER : [//
				// ["global-footer", footerController]//
				]
			}
		},
		unsubscribe_user : {
			title : "お知らせメール解除",
			titleEn : "お知らせメール解除",
			template : "data-templates-invitation.html",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["unsubscribe", unsubscribeController, "MODE_IG_USER"] //
				],
				AREA_FOOTER : [//
				// ["global-footer", footerController]//
				]
			}
		},
		story_3 : {
			title : "IdeaGrid 開発秘話 #3",
			titleEn : "IdeaGrid 開発秘話 #3",
			template : "data-templates-invitation.html",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["story_3", story3Controller] //
				],
				AREA_FOOTER : [["global-footer", footerController]//
				]
			}
		},
		story_2 : {
			title : "IdeaGrid 開発秘話 #2",
			titleEn : "IdeaGrid 開発秘話 #2",
			template : "data-templates-invitation.html",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["story_2", null] //
				],
				AREA_FOOTER : [["global-footer", footerController]//
				]
			}
		},
		story_1 : {
			title : "IdeaGrid 開発秘話 #1",
			titleEn : "IdeaGrid 開発秘話 #1",
			template : "data-templates-invitation.html",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["story_1", invitationController, null] //
				],
				AREA_FOOTER : [["global-footer", footerController]//
				]
			}
		},
		invitation_1119 : {
			title : "KANTETSU WORKS 新作ソフトへのご招待",
			titleEn : "KANTETSU WORKS 新作ソフトへのご招待",
			template : "data-templates-invitation.html",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["story_1", invitationController, "MODE_1119"] //
				],
				AREA_FOOTER : [["global-footer", footerController]//
				]
			}
		},
		invitation_unsubscribe : {
			title : "お知らせメール解除",
			titleEn : "お知らせメール解除",
			template : "data-templates-invitation.html",
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["unsubscribe", unsubscribeController, "MODE_MINDPIECE"] //
				],
				AREA_FOOTER : [//
				// ["global-footer", footerController]//
				]
			}
		},
		terms_of_use : {
			title : "IdeaGrid - Terms of Use and Privacy Policy",
			titleEn : null,
			divs : {
				AREA_HEADER : [//
				["header-set", headerController]//
				],
				AREA_MAIN : [//
				["lang_selector", langController], //
				["terms", termsController]//
				],
				AREA_FOOTER : [//
				["global-footer", footerController]//
				]
			}
		},
		"news-faq" : {
			title : "IdeaGrid - News & FAQ",
			titleEn : null,
			divs : {
				AREA_HEADER : [//
				["header-set", headerController]//
				],
				AREA_MAIN : [//
				["lang_selector", langController], //
				["news", newsController], //
				["faq", faqController]//
				],
				AREA_FOOTER : [//
				["global-footer", footerController]//
				]
			}
		},
		"order" : {//=== 購入フォーム
			title : "IdeaGrid - Order",
			titleEn : null,
			divs : {
				AREA_HEADER : [//
				["header-set", headerController]//
				],
				AREA_MAIN : [//
				["lang_selector", langController], //
				["order", orderController] //
				],
				AREA_FOOTER : [//
				["global-footer", footerController]//
				]
			}
		},
		"paypal" : {//=== 購入フォーム
			title : "IdeaGrid - Moving to PayPal",
			titleEn : null,
			divs : {
				AREA_HEADER : [//
				],
				AREA_MAIN : [//
				["paypal", paypalController] //
				],
				AREA_FOOTER : [//
				]
			}
		}
	};

	_init();
	
	function _init() {
		var cId = KW.Web.getRouteId();
		if (cId == "index" && !/^file/.test(location.href)) {
			location.href = "./new/";
			return;
		}

		//=== will back soon
		// if (!KW.Web.getQuery("route")){
		// cId = "back_soon";
		// }

		//=== AB Test
		if (cId == "teaser") {
			// var testName = "abtest_02";
			//
			// //=== Mod151107 ABTest (a vs b) winner is "teaser_a".
			// //=== Mod151119 ABTest (a vs c) winner is "teaser_a".
			// abTestVal = KW.Web.getQuery("abtest") || "a";
			// // abTestVal = KW.Web.getQuery("abtest") || KW.Web.getCookie(testName);
			// if (!abTestVal) {
			// abTestVal = Math.random() < 0.5 ? "a" : "c";
			// KW.Web.setCookie(testName, abTestVal, null);
			// }
			// console.log("abtest " + testName + " : " + abTestVal);
			// cId = "teaser_" + abTestVal;

		} else if (cId == "invitation") {
			cId += "_" + KW.Web.getQuery("c");
		}

		KW.Web.generateContentD({
			contentId : cId,
			contentDefs : routing,
			divsOrder : divsOrder,
			onContentReady : function(cJ) {
				// retina画像置換
				_initRetinaImage(cJ);
				//=== init LazyLoad
				_initLazyLoad(cJ);
				// 外部リンクを別ウィンドウで開く
				_initLinks(cJ);
			}
		}).then(function() {
			_initUserHeat();
			_initUser();

			setTimeout(function() {
				KW.Web.showContainer();
				// KW.Web.setLoading(false);
				setTimeout(function() {//=== update LazyLoad
					$(window).resize();
					var m;
					if ( m = location.href.match(/(.*)(#[^\?#]*)/)) {
						KW.Web.scrollTo($(m[2]));
						// history.replaceState && history.replaceState(null, null, m[1]);
					}
				}, 10);
			}, 10);
		});
	}

	//=== analytics
	function _initUser() {
		var ui;
		if (!KW.Web.getCookie("last_visit_date")) {
			ui = JSON.parse(KW.Web.getCookie("ui") || null) || {
				lastVisitDate : null,
				visitCount : 0,
				firstPage : location.href
			};
		} else {//=== 下位互換 160908
			ui = {
				lastVisitDate : KW.Web.getCookie("last_visit_date"),
				visitCount : Number(KW.Web.getCookie("visit_count")),
				firstPage : ""
			};
			KW.Web.setCookie("last_visit_date", "");
			KW.Web.setCookie("visit_count", "");
		}

		if (ui.lastVistDate) {//=== スペルミス修正 160909
			ui.lastVisitDate = ui.lastVistDate;
			delete ui.lastVistDate;
		}

		//=== date文字列をオブジェクト化
		if (ui.lastVisitDate) {
			ui.lastVisitDate = new Date(ui.lastVisitDate);
		}

		//=== 30分インターバルがあったら訪問カウントUP
		var d = new Date();
		var d2 = ui.lastVisitDate;
		var isStartSession = (!d2 || (d.getTime() - d2.getTime() > 30 * 60 * 1000));
		isStartSession && ui.visitCount++;

		//=== 保存
		ui.lastVisitDate = d;
		KW.Web.setCookie("ui", JSON.stringify(ui));

		//=== lastVisitDateを戻してグローバルに
		ui.lastVisitDate = d2;
		KW.userInfo = ui;

		isStartSession && setTimeout(function() {
			var s = ui.visitCount//
			+ "_" + __1( d2 ? Math.round((d.getTime() - d2.getTime()) / 1000 / 60) : 0)//
			+ "_" + ((ui.firstPage.replace("http://ideagrid.me", "") || "unknown"));
			_gaEvent("visit2", s, "NO_INTERACTION");
			// console.log(s);
		}, 100);

		function __1(m) {
			var h = Math.floor(m / 60);
			var s;
			if (m == 0) {
				s = "0m";
			} else if (h == 0) {
				s = m + "m";
			} else if (h < 24) {
				s = h + "h";
			} else {
				s = Math.ceil(h / 24) + "d";
			}
			return s;
		}

	}

	function _initRetinaImage(cJ) {
		if (window.devicePixelRatio > 1) {
			cJ.find('img').each(function() {
				if ($(this).hasClass("USE_THIS") || /-2x/.test(this.src))
					return;
				var hasWidth = / width=/.test(this.outerHTML);
				var m = this.src.match(/\.[^\.]+$/);
				m && $(this).attr('src', this.src.replace(m[0], "-2x" + m[0]));
				if (!hasWidth) {
					$(this).css("display", "none");
					$(this).load(function() {
						$(this).attr("width", this.width / 2);
						$(this).css("display", "");
					});
				}
			});
		}
	}

	function _initLazyLoad(cJ) {
		cJ.find("img").each(function(i, elem) {
			var imgj = $(elem);
			imgj.attr("data-original", imgj.attr("src"));
		});
		cJ.find("img").lazyload({
			threshold : 300,
			effect : "fadeIn",
			placeHolder : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/*透明GIF*/
		});
	}

	function _initLinks(cJ) {
		cJ.find('a[href^=http]').not('[href*="' + location.hostname + '"]').attr({
			target : "_blank"
		}).addClass("ex_link");
	}

	function _mtEvent(event, data/* obj or null */) {
		(function(event, data) {
			var o = $.extend({
				page_url : "event?" + event,
				page_title : event,
			}, data || {});
			var img = new Image();
			img.src = "https://kantetsu.mautic.net/mtracking.gif?" + $.param(o);
		})(event, data);
	}

	function _gaEvent(action, actionDetail, mode) {
		var cat = KW.Web.getRouteId() + "_" + verStr;
		var s = actionDetail ? "_" + actionDetail : "";
		var label = action + s + "@" + cat;
		ga('send', 'event', cat, action, label, {
			nonInteraction : /NO_INTERACTION/.test(mode)
		});
		_mtEvent(label);

		KW.Web.getQuery("debug") && console.log(['send', 'event', cat, action, label, mode].join(","));
	}

	function _initUserHeat() {
		(function(add, cla) {
			window['UserHeatTag'] = cla;
			window[cla] = window[cla] ||
			function() {
				(window[cla].q = window[cla].q || []).push(arguments)
			}, window[cla].l = 1 * new Date();
			var ul = document.createElement('script');
			var tag = document.getElementsByTagName('script')[0];
			ul.async = 1;
			ul.src = add;
			tag.parentNode.insertBefore(ul, tag);
		})('//uh.nakanohito.jp/uhj2/uh.js', '_uhtracker');
		_uhtracker({
			id : 'uhQPYvdbzH'
		});
	}

	/*
	 *
	 *
	 * Controllers
	 */

	function saleBannerController(j) {
		j.find('a[href*=#]').click(function(e) {
			_gaEvent("30off_sale_banner");
			KW.Web.scrollTo($("#get_it"));
			return false;
		});
	}

	function promoController(j) {
		_activateForm(true);

		j.find(".mode_1").hide();

		function _activateForm(f) {
			if (f) {
				j.find("form").bind("submit", _send);
				j.find("input").attr("disabled", false);
				j.find("input").css("opacity", 1);
				j.find("input.BUTTON").toggleClass("ENABLED", true);
			} else {
				j.find("form").unbind("submit");
				j.find("input").attr("disabled", true);
				j.find("input").css("opacity", 0.3);
				j.find("input").trigger("blur");
				j.find("input.BUTTON").toggleClass("ENABLED", false);
			}
		}

		function _send(e) {
			e.preventDefault();

			var name = j.find("input[name=name]").val();
			if (!name) {
				alert("お名前をご記入ください。\n\nInvalid name.");
				return;
			}

			var email = j.find("input[name=email]").val();
			if (email.indexOf("@") == -1 || email.indexOf(".") == -1) {
				alert("正しいメールアドレスをご記入ください。\n\nInvalid email.");
				return;
			}

			_activateForm(false);

			KW.Web.setLoading(true);

			$.ajax({
				url : "php/request_promocode.php",
				data : {
					"name" : name,
					"email" : email,
					"media" : j.find("input[name=media]").val(),
					"check_iphone" : j.find("input[name=check_iphone]")[0].checked,
					"check_ipad" : j.find("input[name=check_ipad]")[0].checked,
					"check_mac" : j.find("input[name=check_mac]")[0].checked
				},
				dataType : 'json'
			}).done(function(val) {
				j.find(".mode_0").hide();
				j.find(".mode_1").show();
			}).fail(function(err) {
				alert("Sorry, unexpected error occured.\n\n" + JSON.stringify(err));
				_activateForm(true);
			}).always(function() {
				KW.Web.setLoading(false);
			});

		}

	}

	function v140BannerController(j) {
		j.find("a").on("click", function() {
			_gaEvent("click_v140_banner");
		});
	}

	function mdPlaceHolderController(j, mdPath) {
		KW.Web.isEnglish() && ( mdPath = mdPath.replace(/\.md/, "-en.md"));
		$.get(mdPath).then(function(s) {
			var cJ = $(marked(s));
			_initRetinaImage(cJ);
			_initLazyLoad(cJ);
			_initLinks(cJ);
			cJ.appendTo(j.find(".wrap"));
		});
	}

	function functionListController(j) {
		var funcJs = [];
		var tempJ = j.find(".function");

		KW.Web.translate(tempJ);
		j.find(".list").empty();

		$.get("data-concepts.html").then(function(data) {
			var dataJ = $(data);
			KW.Web.translate(dataJ);

			dataJ.find(".concept_popup").each(function(i, elem) {
				var id = elem.id;
				var j2 = $(elem);

				var newJ = tempJ.clone();
				newJ.find(".desc_area h2 img").attr("src", "images/icon-%id%-2x.png".replace("%id%", id + (KW.Web.isEnglish() ? "-en" : "")));
				newJ.find(".desc_area h2 span").html(j2.find(".title").html());
				newJ.find(".desc_area p").html(j2.find(".desc").html());

				newJ._id = id;
				newJ._figureElem = j2.find(".figure")[0];
				newJ._isShown = false;

				newJ.appendTo(j.find(".list"));

				funcJs.push(newJ);
			});
			
			$(window).scroll(_onScroll);
			setTimeout(_onScroll, 100);
		});

		function _onScroll() {
			var sc = $(this).scrollTop();
			var wh = window.innerHeight;

			$.each(funcJs, function(i, fJ) {
				var t = fJ.offset().top;
				var h = fJ.height();
				if (sc > t - wh + 300 && sc < t + h - 300) {
					!fJ._isShown && _showContent(i, true);
				} else {
					fJ._isShown && _showContent(i, false);
				}
			});
		}

		//=== functions
		function _showContent(i, isOn) {
			(function(i, isOn) {
				var fJ = funcJs[i];
				var cJ = fJ.find(".figure_area .content");
				if (isOn) {
					if (fJ._figureElem.tagName.toLowerCase() == "img") {
						var imgJ = fJ.find(".figure");
						if (imgJ.length == 0) {
							cJ.html(fJ._figureElem.outerHTML);
							imgJ = fJ.find(".figure");
						}
						_replaceSrc(imgJ);
						cJ.hide();
						imgJ[0].onload = function() {
							cJ.fadeIn(500);
						};
					} else {
						cJ.html(fJ._figureElem.outerHTML);
						cJ.hide().fadeIn(500);
					}
					fJ._isShown = true;
					_ga();
				} else {
					cJ.fadeOut(500, function() {
						cJ.empty();
					});
					fJ._isShown = false;
				}

				function _ga() {
					setTimeout(function() {
						fJ._isShown && _gaEvent("see_function", fJ._id, "NO_INTERACTION");
					}, 500);
				}

			})(i, isOn);
		}

		function _replaceSrc(srcJ) {
			srcJ.attr("src", srcJ.attr("src").match(/^(.*?)($|\?)/)[1] + "?" + (new Date()).getTime());
		};
	}

	function lpCampaignController(j) {
		j.find(".campaign_panel").hide();
		j.find(".campaign_bt").click(function() {
			KW.Web.setLoading(true);
			setTimeout(function() {
				KW.Web.setLoading(false);
				_gaEvent("open_campaign");
				j.find(".campaign_bt").toggle("ENABLED", false);
				j.find(".campaign_panel").fadeIn();
			}, 2000);
		});
		j.find(".home_bt").click(function() {
			location.href = "//ideagrid.me/?fr=teaser_home_bt";
		});
	}

	function campaignController(j) {
		j.find(".status_done").hide();
		j.find(".status_ng").hide();
		j.find(".status_form").hide();

		var fj = j.find("form.main_form");

		var cc = _getCampCodeAndRemove();
		if (cc == "93019208647") {
			fj.find("input[name=submit]").on("click", _onClickSubmit);
			j.find(".status_form").show();
		} else {
			j.find(".status_ng").show();
			j.find(".status_form").hide();
		}

		function _getCampCodeAndRemove() {
			var s = "";
			var url = location.href;
			var m = url.match(/campcode=([^&]*)/);
			if (m) {
				s = m[1];
				url = url.replace(m[0], "").replace("?&", "?").replace("&&", "&").replace(/[\?&]$/, "");
				m = url.match(/\/([^\/]+)$/);
				history.replaceState('', '', m[1]);
			}
			return s;
		}

		function _onClickSubmit(e) {
			var btJ = $(this);
			if (btJ.hasClass("ENABLED")) {
				if (_validate() && confirm(_msg("ASK_SUBMIT"))) {
					btJ.toggleClass("ENABLED", false);
					KW.Web.setLoading(true);
					KW.Web.getServerDate().then(function(svDate) {
						_gaEvent("campaign_register");

						fj.find("[name=fld1]").val(orderController.enc("c1", svDate.getTime()));

						$.post(fj.find("[name=send_to]").val(), fj.serialize());
						setTimeout(function() {
							j.find(".status_form").hide();
							j.find(".status_done .desc").html(j.find(".status_done .desc").html().replace(/%email%/, fj.find("[name=email]").val()));
							j.find(".status_done").show();
							KW.Web.setLoading(false);
						}, 4000);
					});
				}

			}
			return false;
		}

		function _validate() {
			if (fj.find("[name=email]").val().match(/[\w\.]+@[\w\.]+/) == null) {
				alert(_msg("NG_EMAIL"));
				return false;
			}
			return true;
		}

		function _msg(className) {
			return j.find("messages ." + className).text();
		}

	};;

	function floatingFooterController(j) {
		j.find(".get_it").on("click", function() {
			KW.Web.scrollTo($("#get_it"));
			_gaEvent("get_it_on_footer");
		});
		j.find(".trial").on("click", function() {
			location.href = "trial.html?fr=footer";
		});

		//=== lp
		j.find(".lp_detail").on("click", function() {
			KW.Web.scrollTo($("#lp_campaign"));
			_gaEvent("lp_scrollto_campaign", "", "NO_INTERACTION");
		});
	}

	function lpEyeCatchController(j, type) {
		id = "default";

		var s = KW.Web.getQuery("fr");
		if (s.match(/tegaki/)) {
			id = "tegaki";
		} else if (s.match(/fusen/)) {
			id = "fusen";
		} else if (s.match(/techo|moleskine|rhodia/)) {
			id = "techo";
		}

		j.find(".TOGGLE").hide();
		j.find(".TOGGLE.TYPE_" + id.toUpperCase()).show();
		j.find("h1 img").attr("src", j.find("h1 img").attr("src").replace("default", id));

		if (id == "tegaki") {
			j.find(".words").css({
				top : "460px"
			});
			j.find(".points").css({
				top : "55px",
				left : "28px",
				color : "#fff"
			});
		} else if (id == "fusen") {
			j.find(".words").css({
				top : "64px",
				letterSpacing : "8px"
			});
			j.find(".points").css({
				top : "300px"
			});
		} else if (id == "techo") {
			j.find(".words").css({
				top : "460px"
			});
			j.find(".points").css({
				top : "55px",
				left : "28px",
				color : "#fff"
			});

		}
	}

	function lpLeadController(j, type) {
		j.find(".TOGGLE").hide();

		var s = KW.Web.getQuery("fr");
		if (s.indexOf("tegaki") > -1) {
			j.find(".TOGGLE.TYPE_TEGAKI").show();
		} else if (s.indexOf("fusen") > -1) {
			j.find(".TOGGLE.TYPE_FUSEN").show();
		} else if (s.indexOf("techo") > -1) {
			j.find(".TOGGLE.TYPE_TECHO").show();
		} else {
			j.find(".TOGGLE.TYPE_DEFAULT").show();
		}
	}

	function lpJointController(j, type) {
		j.find(".TOGGLE").hide();
		j.find(".TOGGLE." + type).show();
	}

	// function voiceListController(j, mode) {
	// if (mode == "TEASER") {
	// j.find(".column").each(function(i, elem) {
	// var j2 = $(elem);
	// j2.find(".person").each(function(ii, elem2) {
	// if (j2.find(".person").length > 4) {
	// $(elem2).remove();
	// }
	// });
	// });
	// }
	// }

	function trialController(j) {
		j.find(".status_done").hide();

		var fj = j.find("form.main_form");

		if (KW.Web.isEnglish()) {
			fj.find(".JA_ONLY").remove();
			fj.find("[placeholder-en]").each(function(i, elem) {
				$(this).attr("placeholder", $(this).attr("placeholder-en"));
			});
			fj.find("[value-en]").each(function(i, elem) {
				$(this).attr("value", $(this).attr("value-en"));
			});
			var n1j = fj.find("[name=name1]");
			n1j.detach();
			fj.find("[name=name2]").after(n1j);
		}

		fj.find("input[name=submit]").on("click", _onClickSubmit);

		function _onClickSubmit(e) {
			var btJ = $(this);
			if (btJ.hasClass("ENABLED")) {
				if (_validate() && confirm(_msg("ASK_SUBMIT"))) {
					btJ.toggleClass("ENABLED", false);
					KW.Web.setLoading(true);
					KW.Web.getServerDate().then(function(svDate) {
						_gaEvent("trial_register");

						// e.preventDefault();
						// return;
						fj.find("[name=fld1]").val(orderController.enc("tr4", svDate.getTime()));

						$.post(fj.find("[name=send_to]").val(), fj.serialize());
						//=== Domain Origin Error でコールバックされない様子（Safari）
						// $.post(url, fj.serialize()).then(function(txt) {
						// alert(txt);
						// }).always(function() {
						// KW.Web.setLoading(false);
						// });

						_mtEvent("register_trial", {
							lastname : fj.find("[name=name1]").val(),
							firstname : fj.find("[name=name2]").val(),
							email : fj.find("[name=email]").val(),
							istrial : 1
						});

						setTimeout(function() {
							j.find(".status_form").hide();
							j.find(".status_done .desc").html(j.find(".status_done .desc").html().replace(/%email%/, fj.find("[name=email]").val()));
							j.find(".status_done").show();
							KW.Web.setLoading(false);
						}, 4000);
					});
				}

			}
			return false;
		}

		function _validate() {
			if (!fj.find("[name=name1]").val() || !fj.find("[name=name2]").val()) {
				alert(_msg("NG_NAME"));
				return false;
			}
			if (fj.find("[name=email]").val().match(/[\w\.]+@[\w\.]+/) == null) {
				alert(_msg("NG_EMAIL"));
				return false;
			}
			return true;
		}

		function _msg(className) {
			return j.find("messages ." + className).text();
		}

	};

	function downloadControlelr(j) {
		var j2 = j.find(".send");
		if (KW.Web.isEnglish()) {
			j2.attr("value", j2.attr("value-en"));
		}
		j.find(".send").bind("click", function(e) {
			e.preventDefault();

			var email = $("input.email").val();
			if (email.indexOf("@") == -1 || email.indexOf(".") == -1) {
				var msg = KW.Web.isEnglish() ? "Invalid email address." : "正しいメールアドレスをご記入ください";
				alert(msg);
				return;
			}

			KW.Web.setLoading(true);

			$.ajax({
				url : "php/verify_user.php",
				data : j.find("form").serialize(),
				dataType : 'json'
			}).done(function(res) {
				if (res.result) {
					_gaEvent("click_download");
					location.href = "https://s3-ap-northeast-1.amazonaws.com/ideagrid-archive/IdeaGrid_mac_v1.1.1.dmg";
				} else {
					if (res.error) {
						alert("Sorry, could not download.\n\n" + res.error);
					} else {
						$.Deferred().reject(res);
					}
				}
			}).fail(function(err) {
				alert("Sorry, unexpected error occured.\n\n" + JSON.stringify(err));
			}).always(function() {
				KW.Web.setLoading(false);
			});

		});
	}

	function dropboxBannerController(j) {
		j.find("a").on("click", function() {
			_gaEvent("click_dropbox_banner");
		});
	}

	function voiceController(j) {
		//=== よくわからないバグ。timeoutで回避。
		setTimeout(function() {
			j.find('a[href*=#]').on("click", function(e) {
				_gaEvent("scroll_to_voice", "from_article", "NO_INTERACTION");
				KW.Web.scrollTo($(this.hash));
				return false;
			});
		}, 100);
	}

	function giftInquiryController(j) {
		j.find(".mode_1").hide();
		_activateForm(true);

		function _activateForm(f) {
			if (f) {
				j.find(".send").bind("click", _send);
			} else {
				j.find(".send").unbind("click");
			}
		}

		function _send(e) {
			e.preventDefault();

			_activateForm(false);

			KW.Web.setLoading(true);

			$.ajax({
				// url : "static/iap_plan_info.json",
				url : "php/inquiry.php",
				data : j.find("form").serialize(),
				dataType : 'json'
			}).done(function(val) {
				j.find(".mode_0").hide();
				j.find(".mode_1").show();
			}).fail(function(err) {
				alert("Sorry, unexpected error occured.\n\n" + JSON.stringify(err));
				_activateForm(true);
			}).always(function() {
				KW.Web.setLoading(false);
			});

		}

	}

	function wsBannerController(j, gaEventOption) {
		if (!KW.Web.isEnglish()) {
			j.find("a").on("click", function() {
				_gaEvent("click_ws_banner", gaEventOption);
			});
		} else {
			j.hide();
		}
	}

	function hiwaBannerController(j) {
		if (!KW.Web.isEnglish()) {
			j.find("a").on("click", function() {
				_gaEvent("click_hiwa_banner");
			});
		} else {
			j.hide();
		}
	}

	function limitedSaleController(j, dToStr/*"2016/8/31"*/) {
		var d = new Date();
		var dTo = new Date(dToStr);
		var msec = dTo - d;
		var day = Math.max(0, Math.ceil(msec / 86400000));
		//1日は86400000ミリ秒
		j.find(".left_days").text(day);
		j.find(".end_date").text(dToStr);
	}

	function story3Controller(j) {
		(function(j) {
			j.find(".movie").bind("click", _1);

			function _1() {
				_gaEvent("watch_video");
				j.find(".youtube").html('<iframe width="100%" height="100%" src="http://www.youtube.com/embed/Fq3WlRIVamw?rel=0&controls=1&showinfo=0&vq=large" frameborder="0" allowfullscreen></iframe>');
			}

		})(j);
	}

	function unsubscribeController(j, mode/*MODE_MINDPIECE or MODE_IDEAGRID or MODE_IG_UESR*/) {
		_activateForm(true);

		j.find(".mode_1").hide();

		function _activateForm(f) {
			if (f) {
				j.find(".send").bind("click", _send);
				j.find("form").bind("submit", _send);
				j.find("input.email")[0].disabled = false;
				j.find("input.email").css("opacity", 1);
			} else {
				j.find(".send").unbind("click");
				j.find("form").unbind("submit");
				j.find("input.email")[0].disabled = true;
				j.find("input.email").css("opacity", 0.3);
				j.find("input.email").trigger("blur");
			}
		}

		function _send(e) {
			e.preventDefault();

			var email = $("input.email").val();
			if (email.indexOf("@") == -1 || email.indexOf(".") == -1) {
				alert("正しいメールアドレスをご記入ください。\n\nInvalid email.");
				return;
			}

			_activateForm(false);

			KW.Web.setLoading(true);

			$.ajax({
				url : "php/unsubscribe2.php",
				data : {
					"email" : email,
					"mode" : mode
				},
				dataType : 'json'
			}).done(function(val) {
				j.find(".mode_0").hide();
				j.find(".mode_1 .email").text(email);
				j.find(".mode_1").show();
			}).fail(function(err) {
				alert("Sorry, unexpected error occured.\n\n" + JSON.stringify(err));
				_activateForm(true);
			}).always(function() {
				KW.Web.setLoading(false);
			});

		}

	}

	function invitationController(j, mode) {
		if (mode == "MODE_1119") {
			j.find(".not_1119").hide();
		} else {
			j.find(".1119").hide();
		}
		j.find(".send").bind("click", _send);
		j.find("form").bind("submit", _send);

		function _send(e) {
			e.preventDefault();

			var email = $("input.email").val();
			if (email.indexOf("@") == -1 || email.indexOf(".") == -1) {
				alert("正しいメールアドレスをご記入ください。\n\nInvalid email.");
				return;
			}

			j.find(".send").unbind("click");
			j.find("input.email")[0].disabled = true;
			j.find("input.email").css("opacity", 0.3);

			KW.Web.setLoading(true);

			_gaEvent("start_trial");

			$.ajax({
				url : "php/invitation.php",
				data : {
					"email" : email,
					"lang" : KW.Web.isEnglish() ? "en" : "ja"
				},
				dataType : 'json'
			}).done(function(val) {
				j.find("#conversion").html('<iframe src="conversion.html?' + new Date().getTime() + '" frameborder="0" scrolling="no" width="1" height="1"></iframe>');
				alert("ご登録いただき心より感謝いたします。最新情報を楽しみにお待ちください。");
			}).fail(function() {
				alert("Sorry, unexpected error occured.");
			}).always(function() {
				KW.Web.setLoading(false);
			});

		}

	}

	function devStoryController(j) {
		j.add("<div></div>");
		$.get("data-teaser.html").then(function(html) {
			j.html(html);
			KW.Web.translate(j);
		});
	}

	function orderController(j) {
		_initFancybox();

		j.find(".status_done").hide();

		var fj = j.find("form.main_form");
		var pfj = j.find("form.paypal_form").remove();

		//=== クーポン処理 Mod160216
		j.find(".coupon").hide();
		var code = KW.Web.getQuery("coupon");
		if (code) {
			var c = orderController.dec(KW.Web.getQuery("coupon"));
			if (c) {
				KW.Web.getServerDate().then(function(svDate) {
					var d = c.period - Math.floor((svDate.getTime() - c.startTime) / (24 * 60 * 60 * 1000));
					if (d >= 0) {
						var basePer = j.find("[name=price_off_per]").val();
						var sumPer = ( 1.0 - ((100-basePer)/100) * ((100-c.per)/100) ) * 100;
						var per = Math.round(sumPer * 100) /100  /* 15.00000002を回避 */;
						per = Math.ceil(per);
						j.find("[name=price_off_per]").val(per);
						_updatePrices();
						j.find(".coupon .msg").text(_msg("COUPON_OK").replace(/%per%/, c.per).replace(/%days%/, d));
					} else {
						j.find(".coupon .msg").text(_msg("COUPON_NG"));
					}
					j.find(".coupon").show();
				});
			} else {
				setTimeout(function() {//=== /en:対応のための苦肉の作
					j.find(".coupon .msg").text(_msg("COUPON_NG"));
					j.find(".coupon").show();
				}, 10);
			}
		}

		if (KW.Web.isEnglish()) {
			fj.find(".JA_ONLY").remove();
			fj.find("[placeholder-en]").each(function(i, elem) {
				$(this).attr("placeholder", $(this).attr("placeholder-en"));
			});
			fj.find("[price-en]").each(function(i, elem) {
				$(this).attr("price", $(this).attr("price-en"));
			});
			fj.find("[value-en]").each(function(i, elem) {
				$(this).attr("value", $(this).attr("value-en"));
			});
		}

		fj.find("input[name=license_radio]").on("change", _updatePrices);
		fj.find("input[name=amount]").on("change", _updatePrices);
		fj.find("input[name=submit]").on("click", _onClickSubmit);

		_updatePrices();

		function _updatePrices() {
			var s = fj.find("[name=amount]").val().replace(/\D/g, "");
			fj.find("[name=amount]").val(Math.max(s, 4));
			fj.find("[name=license_name]").val("");
			fj.find("[name=paypal_order_info]").val("");

			var priceOffPer = fj.find("[name=price_off_per]").val();
			var selIndex = -1;
			fj.find(".FIELD_RADIO_GROUP").toggleClass("NO_DISCOUNT", priceOffPer == 0);
			fj.find("[name=license_radio]").each(function(i, elem) {
				rj = $(this);
				var pj = $(fj.find(".license_radio_price")[i]);
				var offR = (100 - priceOffPer) / 100;
				var price = __roundPrice(rj.attr("price") * offR);
				var amount = price;
				var suffix = "";
				pj.text(price);
				rj.val(rj.val().replace("%p%", price));
				fj.find(".price_off").text("(%p%% OFF)".replace("%p%", priceOffPer));
				if (rj.hasClass("multi_license")) {
					var qty = Number(fj.find("[name=amount]").val());
					amount = price * qty;
					suffix = " " + qty;
					fj.find(".license_radio_price_amount").text(amount);
				}
				if (rj.is(":checked")) {
					fj.find("[name=total_amount]").val(amount);
					fj.find(".total_amount_price").text(amount);

					fj.find("[name=license_name]").val(rj.val() + suffix);

					//=== paypal_order_info = [name, amount, countryCode]
					var ls = [rj.attr("value-en") + suffix, amount, fj.find("[name=country_code]").val()];
					fj.find("[name=paypal_order_info]").val(KW.Web.utf8ToBase64(JSON.stringify(ls)));
				}

				function __roundPrice(p) {
					if (KW.Web.isEnglish()) {
						return Math.round(p * 100) / 100;
					} else {
						return Math.floor(p);
					}
				}

			});
		}

		function _onClickSubmit(e) {
			var btJ = $(this);
			if (btJ.hasClass("ENABLED")) {
				if (_validate() && confirm(_msg("ASK_SUBMIT"))) {
					// location.href = "paypal.html?info=" + fj.find("[name=paypal_order_info]").val();
					// return false;
					// fj.attr("action", "http://kantetsu.sakura.ne.jp/ideagrid_me_dev/php/order.php");
					// return;

					KW.Web.setLoading(true);

					_gaEvent("order");

					var url = "php/order.php";
					if (location.href.indexOf("file://") == 0) {
						url = "http://kantetsu.sakura.ne.jp/ideagrid_me_dev/" + url;
						if (!confirm("Local test. OK?\n" + url)) {
							return false;
						}
					}

					btJ.toggleClass("ENABLED", false);
					$.post(url, fj.serialize()).then(function(txt) {

						_mtEvent("order", {
							fullname : fj.find("[name=name]").val(),
							email : fj.find("[name=email]").val(),
							isordered : 1
						});

						var o = KW.Web.parseJson(txt) || {};
						if (o.result) {
							j.find(".status_done").show();
							j.find(".status_order").hide();
							if (fj.find("[name=pay_type_index]").val() == 0) {
								setTimeout(function() {
									if (confirm(_msg("ASK_GO_PAYPAL"))) {
										location.href = "paypal.html?info=" + fj.find("[name=paypal_order_info]").val();
									}
								}, 100);
							}
						} else {
							alert(_msg("ERROR_ABORT") + "\n\n" + JSON.stringify(o));
							btJ.toggleClass("ENABLED", true);
						}
					}).always(function() {
						KW.Web.setLoading(false);
					});
				}
			}
			return false;
		}

		function _validate() {
			if (fj.find("[name=name]").val() == "") {
				alert(_msg("NG_NAME_KANJI"));
				return false;
			}
			if (!KW.Web.isEnglish() && fj.find("[name=name_kana]").val() == "") {
				alert(_msg("NG_NAME_KANA"));
				return false;
			}
			if (fj.find("[name=email]").val().match(/[\w\.]+@[\w\.]+/) == null) {
				alert(_msg("NG_EMAIL"));
				return false;
			}
			if (fj.find("[name=email]").val() != fj.find("[name=email_confirm]").val()) {
				alert(_msg("NG_EMAIL_CONFIRM"));
				return false;
			}
			_updatePrices();
			if (fj.find("[name=total_amount]").val() == 0) {
				alert(_msg("NG_LICENSE"));
				return false;
			}

			if (!KW.Web.isEnglish()) {
				var payTypeIndex = -1;
				fj.find("[name=pay_type_radio]").each(function(i, elem) {
					if ($(this).is(":checked")) {
						payTypeIndex = i;
					}
				});
				fj.find("[name=pay_type_index]").val(payTypeIndex);
				if (payTypeIndex == -1) {
					alert(_msg("NG_PAYMENT_TYPE"));
					return false;
				}
				if (payTypeIndex == 1 && fj.find("[name=bank_name]").val() == "") {
					alert(_msg("NG_BANK_NAME"));
					return false;
				}
			} else {
				fj.find("[name=pay_type_index]").val(0);
			}

			if (fj.find("[name=terms]").is(":checked") == false) {
				alert(_msg("NG_TERMS"));
				return false;
			}

			return true;
		}

		function _msg(className) {
			return j.find("messages ." + className).text();
		}

	};
	//=== orderControllerをstaticとして利用
	KW.orderController = orderController;
	$.extend(orderController, {
		enc : function(type, startTime) {
			return this._conv(type + "_" + startTime, true);
		},
		dec : function(code) {
			var s = this._conv(code, false);
			var m;
			if ( m = s.match(/sp50_(\d+)$/)) {//==="sp50_00000000000" 特別50%オフ
				return {
					per : 50,
					period : 10,
					startTime : Number(m[1])
				};
			} else if ( m = s.match(/rev2_(\d+)$/)) {//==="rev2_00000000000"
				return {
					per : 21,
					period : 10,
					startTime : Number(m[1])
				};
			} else if ( m = s.match(/rev1_(\d+)$/)) {//==="rev1_00000000000"
				return {
					per : 39,
					period : 10,
					startTime : Number(m[1])
				};
			} else if ( m = s.match(/tr4_(\d+)$/)) {//==="tr4_00000000000"
				return {
					per : 15,
					period : 10,
					startTime : Number(m[1])
				};
			} else if ( m = s.match(/tr3_(\d+)$/)) {//==="tr3_00000000000"
				return {
					per : 30,
					period : 10,
					startTime : Number(m[1])
				};
			} else if ( m = s.match(/c1_(\d+)$/)) {//==="c1_00000000000"
				return {
					per : 20,
					period : 7,
					startTime : Number(m[1])
				};
			} else if ( m = s.match(/tr2_(\d+)$/)) {//==="tr2_00000000000"
				return {
					per : 20,
					period : 10,
					startTime : Number(m[1])
				};
			} else if ( m = s.match(/tr_?(\d+)$/)) {//=== 旧タイプ "tr809879890" もあるので下位互換
				return {
					per : 15,
					period : 10,
					startTime : Number(m[1])
				};
			}
		},
		_conv : function(s, isEnc) {
			var s2 = "";
			if (isEnc) {
				for (var i = 0; i < s.length; i++) {
					s2 += s.charCodeAt(i).toString(16);
				}
			} else {
				for (var i = 0; i < s.length; i = i + 2) {
					s2 += String.fromCharCode(parseInt(s.substr(i, 2), 16));
				}
			}
			return s2;
		}
	});

	function paypalController(j) {
		var fj = j.find("form");
		var info;

		try {
			var m = location.href.match(/info=([^&|^$]+)/);
			info = JSON.parse(KW.Web.base64ToUtf8(m[1]));
		} catch(e) {
		}

		if (info) {
			fj.find("input[name=item_name]").val(//
			fj.find("input[name=item_name]").val().replace("%license%", info[0]));

			fj.find("input[name=amount]").val(info[1]);
			fj.find("input[name=currency_code]").val(info[2] == "en" ? "USD" : "JPY");
			fj.find("input[name=lc]").val(info[2] == "en" ? "US" : "JP");
			setTimeout(function() {
				fj.submit();
			}, 50);
		} else {
			j.find(".message").text("Error: Order information for PayPal is not found.");
		}
	}

	function headerController(j) {
		// indexだったら左上ボタンも#に
		if (KW.Web.getRouteId() == "index") {
			j.find("#site-title a").attr("href", "#");
		}

		// ページ内スクロール
		j.find('a[href*=#]').click(function(e) {
			if (KW.Web.getRouteId() == "index") {
				_gaEvent("click_header", this.hash.replace("#", ""));
				KW.Web.scrollTo($(this.hash));
				return false;
			}
		});

		// メニューハイライト
		var menus = [/*[id, top, elem],*/ ];
		j.find("nav ul li a[href*=#]").each(function(i, elem) {
			menus.push([elem.href.match(/\#(.*)/)[1], elem]);
		});

		var activeNo = -1;
		$(window).scroll(function() {
			var sc = $(this).scrollTop();
			var i2 = -1;
			$.each(menus, function(i, val) {
				var areaJ = $("#" + val[0]);
				if (areaJ.length) {
					sc + 80 > areaJ.offset().top && ( i2 = i);
				}
			});
			$.each(menus, function(i, val) {
				$(val[1]).toggleClass("active", i == i2);
			});
			if (i2 != activeNo) {
				activeNo = i2;
				_ga(activeNo);
			}
		});

		function _ga(i) {
			(function(i) {
				setTimeout(function() {
					if (i == activeNo) {
						var id = i > -1 ? menus[i][0] : "top";
						_gaEvent("see_section", id, "NO_INTERACTION");
					}
				}, 500);
			})(i);
		}

	}

	function langController(j, mode) {
		if (mode == "MODE_HIDDEN") {
			j.css("visibility", "hidden");
		} else {
			var m = location.href.match(/\/([\w\.-]+)($|\?|#)/);
			var fname = m ? m[1] : "";
			j.find("a.ja").attr("href", fname + "?lang=ja").toggleClass("ON", !KW.Web.isEnglish());
			j.find("a.en").attr("href", fname + "?lang=en").toggleClass("ON", KW.Web.isEnglish());
		}
	}

	var _movId = 0;

	function watchVideoController(j) {
		var tabs = ["pv", "ipad", "iphone"];
		var activeNo = 0;

		j.find(".movie").bind("click", _clickMovie);
		j.find(".tabs li").bind("click", _clickTab);

		function _clickTab(e) {
			var tj = $(this);
			$.each(tabs, function(i, val) {
				if (tj.attr("class").indexOf(val) > -1) {
					activeNo = i;
					return 0;
				}
			});
			j.find(".tabs li").each(function(i, elem) {
				$(elem).toggleClass("ACTIVE", i == activeNo);
			});
			_clickMovie();
		}

		function _clickMovie() {
			_gaEvent("watch_video", tabs[activeNo]);
			var tj = j.find(".tabs ." + tabs[activeNo]);
			j.find(".youtube").html(tj.attr("data-url"));
		}

	}

	function catchController(j) {
		var catchType = "IDEA";
		//location.href.match(/keieisya/) ? "KEIEISYA" : location.href.match(/kikaku/) ? "KIKAKU" : "IDEA";
		$.each(["KIKAKU", "KEIEISYA", "IDEA"], function(i, val) {
			j.find(".copy").toggleClass(val, val == catchType);
		});

	}

	function conceptController(j, mode) {
		if (mode == "MODE_HIDE_ICONS") {
			j.find("ul").hide();
		} else {
			_initFunctionIconButtons(j);
		}
	}

	function shikumiController(j, mode) {
		if (mode == "MODE_HIDE_ICONS") {
			j.addClass("HIDE_ICONS");
		} else {
			_initFunctionIconButtons(j);
		}
	}

	function _initFunctionIconButtons(j) {
		_initFancybox();
		if (j.find("a.fancybox").length) {
			$.get("data-concepts.html").then(function(data) {

				var isEn = KW.Web.isEnglish();
				var temp = KW.Web.getTempJ().find("#popup-template").html();
				var dataJ = $(data);

				KW.Web.translate(dataJ);

				j.find("a.fancybox").each(function(i, elem) {
					var id = elem.href.match(/concept-\d+-\d+/)[0];
					var j = dataJ.find("#" + id);
					var figJ = j.find(".figure");

					$(elem).fancybox({
						content : temp//
						.replace("{{id}}", id + ( isEn ? "-en" : ""))//
						.replace("src-tmpl", "src")//
						.replace("{{title}}", j.find(".title").html())//
						.replace("{{desc}}", j.find(".desc").html())//
						.replace("{{figure}}", figJ[0].outerHTML)
					});

					$(elem).click(function() {
						//=== アニメーションGIF巻き戻し
						var fj = $(".fancybox-wrap").find(".figure");
						var url = fj.attr("src");
						url = url.match(/^(.*?)($|\?)/)[1] + "?" + (new Date()).getTime();
						fj.attr("src", url);

						var id;
						try {
							id = elem.href.match(/\d+-\d/)[0].replace("-", "_");
							// alert(id);
						} catch (e) {
						}
						_gaEvent("click_detail", id);
					});
				});
			});
		}
	}

	function getButtonController(j, mode) {
		j.find('a[href*=#]').click(function(e) {
			if ($(this.hash).length) {
				KW.Web.scrollTo($(this.hash));
				return false;
			}
		});
		j.find("div.get_it").on("click", function() {
			KW.Web.scrollTo($("#get_it"));
		});
		j.find(".video").on("click", function() {
			KW.Web.scrollTo($("#watch_video"));
		});
	}

	function moreDetailsController(j) {
		j.find(".image_area").click(function(e) {
			_gaEvent("lp_to_home", "gif");
			location.href = "//ideagrid.me/?fr=teaser_gif";
		});

		j.find(".details").on("click", function() {
			_gaEvent("lp_to_home");
			location.href = "./?fr=teaser";
		});
		j.find(".trial").on("click", function() {
			_gaEvent("click_trial_from_teaser");
			location.href = "trial.html?fr=teaser";
		});
	}

	function getItController(j, mode) {
		if (mode == "ONLY_TABLE") {
			j.find("h1").text("IdeaGrid 機能比較表/en:IdeaGrid Features Table");
			j.find("tfoot").hide();
		}

		j.find(".camp form").bind("submit", _send);
		j.find(".store a").bind("click", _goStore);

		function _send(e) {
			e.preventDefault();
			var cc = j.find("[name=campcode]").val();
			location.href = "./?route=campaign&campcode=" + cc;
		}

		function _goStore(e) {
			_gaEvent("click_store", $(this).attr("data-device"));
		}

	}

	function teaserController(j) {
		_updateNumber();

		j.find(".send").bind("click", _send);
		j.find("form").bind("submit", _send);

		function _updateNumber() {
			j.find(".number span").text("");
			$.get("php/data/number.txt?" + new Date().getTime()).then(function(s) {
				j.find(".number span").text(s);
			});
		}

		function _send(e) {
			e.preventDefault();

			var email = $("input.email").val();
			if (email.indexOf("@") == -1 || email.indexOf(".") == -1) {
				alert("正しいメールアドレスをご記入ください。\n\nInvalid email.");
				return;
			}

			j.find(".send").unbind("click");
			j.find("input.email")[0].disabled = true;
			j.find("input.email").css("opacity", 0.3);

			KW.Web.setLoading(true);

			//            setTimeout(function () {
			//                waitJ.remove();
			//                $.fancybox.hideLoading();
			//            }, 1000);
			//            return;

			_gaEvent("register");

			$.ajax({
				url : "php/test.php",
				data : {
					"email" : email,
					"lang" : KW.Web.isEnglish() ? "en" : "ja"
				},
				dataType : 'json'
			}).done(function(val) {
				_updateNumber();
				j.find("#conversion").html('<iframe src="conversion.html?' + new Date().getTime() + '" frameborder="0" scrolling="no" width="1" height="1"></iframe>');
				alert("ご登録いただき心より感謝いたします。リリースを楽しみにお待ちください。\n\nThank you for joining us! We'll tell you when our service is ready.");
			}).fail(function() {
				alert("Sorry, unexpected error occured.");
			}).always(function() {
				KW.Web.setLoading(false);
			});

		}

	}

	function footerController(j, mode) {
		if (mode) {
			if (mode.match(/MODE_HIDE_TERMS/)) {
				j.find(".link").css("visibility", "hidden");
			}
			if (mode.match(/MODE_SIMPLE/)) {
				j.find("#support").hide();
				j.find("#credits").hide();
				j.find(".link").hide();
			}
		}

		var isScrolled = false;
		var footerH;
		$(window).on('scroll', function(e) {
			footerH = footerH || j.height();
			var b = $(window).scrollTop() + window.innerHeight > $(document).height() - footerH;
			if (b != isScrolled) {
				isScrolled = b;
				setTimeout(function() {
					isScrolled && _gaEvent("scroll_to_bottom", "", "NO_INTERACTION");
				}, 500);
			}
		});

	}

	function termsController(j) {
		var en = KW.Web.isEnglish() ? "-en" : "";
		$.get("static/terms" + en + ".html").then(function(s) {
			j.find(".wrap").html(s);
		});
	}

	function newsController(j, maxItems) {
		j.find(".data").empty();
		$.get("static/data-news.html").then(function(html) {
			j.find(".data").html(html);
			j.find("style").remove();

			if (maxItems > 0) {
				j.find(".data li").each(function(i, elem) {
					(i > maxItems - 1) && $(elem).remove();
				});
			}

			KW.Web.translate(j);
		});
	}

	function faqController(j) {
		j.find(".data").empty();
		$.get("static/data-faq.html").then(function(html) {

			//=== FAQでリンクが使えないので苦肉の処理
			html = html.replace(/(ideagrid.me\/guide.html)/g, function($substring, $0) {
				return "<a href='guide.html'>" + $substring + "</a>";
			});

			j.find(".data").html(html);
			j.find("style").remove();
			KW.Web.translate(j);
		});
	}

}

